const data = [
    {
        id: 1,
        idPengguna: 124019314012,
        namaPengguna: "Bayu Arianto",
        kewarganegaraan: "Indonesia",
        nomorKependudukan: 1123019238104810,
        tgl: "1 Feb 2021, 09:30",
        estimasi: "2 Feb 2021, 09:30",
    },
    {
        id: 2,
        idPengguna: 124019314012,
        namaPengguna: "Endang Sugiarti",
        kewarganegaraan: "Indonesia",
        nomorKependudukan: 1123019238104810,
        tgl: "1 Feb 2021, 09:30",
        estimasi: "2 Feb 2021, 09:30",
    },
    {
        id: 3,
        idPengguna: 124019314012,
        namaPengguna: "Harry Susanto",
        kewarganegaraan: "Indonesia",
        nomorKependudukan: 1123019238104810,
        tgl: "1 Feb 2021, 09:30",
        estimasi: "2 Feb 2021, 09:30",
    },
    {
        id: 4,
        idPengguna: 124019314012,
        namaPengguna: "Irwan Siregar",
        kewarganegaraan: "Indonesia",
        nomorKependudukan: 1123019238104810,
        tgl: "1 Feb 2021, 09:30",
        estimasi: "2 Feb 2021, 09:30",
    },
    {
        id: 5,
        idPengguna: 124019314012,
        namaPengguna: "Kimin Tanoto",
        kewarganegaraan: "Indonesia",
        nomorKependudukan: 1123019238104810,
        tgl: "1 Feb 2021, 09:30",
        estimasi: "2 Feb 2021, 09:30",
    },
    {
        id: 6,
        idPengguna: "kettle",
        namaPengguna: "inspection",
        kewarganegaraan: 10,
        nomorKependudukan: 78,
        tgl: 8,
        estimasi: "single",
    },
    {
        id: 7,
        idPengguna: "kettle",
        namaPengguna: "inspection",
        kewarganegaraan: 10,
        nomorKependudukan: 78,
        tgl: 9,
        estimasi: "single",
    },
    {
        id: 8,
        idPengguna: "kettle",
        namaPengguna: "inspection",
        kewarganegaraan: 10,
        nomorKependudukan: 78,
        tgl: 8,
        estimasi: "single",
    },
    {
        id: 9,
        idPengguna: "kettle",
        namaPengguna: "inspection",
        kewarganegaraan: 10,
        nomorKependudukan: 78,
        tgl: 8,
        estimasi: "single",
    },
    {
        id: 10,
        idPengguna: "kettle",
        namaPengguna: "inspection",
        kewarganegaraan: 10,
        nomorKependudukan: 78,
        tgl: 8,
        estimasi: "single",
    },
];

export default data;
