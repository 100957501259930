import React from "react";

function Message() {
    return (
        <div>
            <p>Message Page</p>
        </div>
    );
}

export default Message;
