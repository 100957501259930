import React from "react";

function Master() {
    return (
        <div>
            <p>Master Page</p>
        </div>
    );
}

export default Master;
